<template>
    <div class="shalong">
        <!-- <div class="back_title">
            分享
        </div> -->
        <div class="shalong_top">
            <div class="bg">
                <img src="../../assets/logoTwo.png" alt="">  
            </div>  
        </div>

        <div class="btn_box">
            <van-button type="default" @click="goMoreTwo()">进入天猫商城</van-button>
            <van-button type="default" @click="goMore()">进入京东商城</van-button>
             <!-- <van-button type="default" >进入天猫商城</van-button>
            <van-button type="default" >进入京东商城</van-button> -->
        </div>
    </div>
</template>
<script>

// function setJumpUrl(data) {
//                 Toast('111111111111111111111111')
//                 if(data) {
//                     Toast(JSON.parse(data))
//                 }
//             }
//import Vue from 'vue'
import { Toast } from 'vant';
    export default {
        data () {
            return {
                goJD: '',
                goTB: '',
                isAndroid: '',
                isiOS: '',
            }
        },

        mounted() {
          
          //alert(JSON.stringify(window))
            // if(window.mirror) {
            //     Toast(333333333333)
            //     let path = JSON.parse( window.mirror.getJumpUrl())
            //     this.goJD = path.jd
            //     this.goTB = path.tb 
            // } else {
            //    Toast('暂未开放！')
            // }
            var u = navigator.userAgent;

            this.isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
            this.isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
            console.log(this.isAndroid, this.isiOS)
            window.$setJumpUrl = this.setJumpUrl
            this.test()
        },
        methods: {
            test() {
                if (this.isAndroid == true) {
                    if(window.mirror) {
                        let path = JSON.parse( window.mirror.getJumpUrl())
                        this.goJD = path.jd
                        this.goTB = path.tb 
                    } else {
                       Toast('暂未开放！')
                    }
                } else if(this.isiOS == true){
                    window.webkit.messageHandlers.getJumpUrl.postMessage('')
                } else {
                    console.log('pc端')
                }
            },
            setJumpUrl(data) {
                if(data) {
                    this.goJD = JSON.parse(data).jd
                    this.goTB = JSON.parse(data).tb
                    // Toast(JSON.parse(data).jd)
                   // this.goJD = JSON.parse(data)
                }
            },
            goMore() {
                if(this.goJD != '') {
                     window.location.href = this.goJD
                } else {
                    Toast('暂未开放！')
                }
            },
            goMoreTwo() {
                if(this.goTB != '') {
                    window.location.href = this.goTB
                } else {
                    Toast('暂未开放！')
                }
                
            }
        }
    }
</script>
<style  lang='scss' >
::-webkit-scrollbar{ 
    display: none;
}
.shalong {
    background: linear-gradient(178deg, #2E333A 0%, #000000 100%);
    width: 100%;
    height: 100%;
    overflow: hidden;
    overflow-x: hidden;
    overflow-y: auto;
    .shalong_top {
        width:100%;
        padding-top: 15%;
        overflow-y: auto;
        overflow-x: hidden;
        height: calc(100% - 180px);
        .bg {
            width: 500px;
            height: 1200px;
            margin-left: 50%;
            transform: translateX(-50%);
            background: url('../../assets/bggt.png') ;
             background-size: 100% 100%;
             position: relative;
             img {
                position: absolute;
                bottom: 59px;
                width: 322px;
                height: 56px;
                left: 50%;
                transform: translateX(-50%);
             }
        }
    }
    .btn_box {
        width: 100%;
        position: absolute;
        bottom: 40px;
        display: flex;
        justify-content: space-around;
        .van-button {
            width: 45%;
            display: inline-block;
            height: 48px;
            background: #ffffff;
            border-radius: 6px;
            font-size: 15px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: black;
        }
    }
}
</style>